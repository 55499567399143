<template>
    <div ref="preview" :style="previewStyles">
    </div>
</template>

<script>
import {Preview} from '@creatomate/preview';

export default {
  name: "MediaPreview",
  props: {
    code: {},
    values: {},
    width: {},
    height: {},
  },
  mounted() {
    //this.$refs.preview.innerHTML = this.code
    this.$watch("code", this.start, {
      immediate: true
    });

    // Watch for values
    this.$watch("values", this.updateValues, {deep: true});
  },

  data: () => ({
    player: null
  }),

  /**
   * Destroy player
    */
  beforeUnmount() {
    console.log('destroy')
    if(this.player) {
      this.player.dispose();
    }
  },

  methods: {

    /**
     * Update values
     * @return {Promise<void>}
     */
    async updateValues() {
      // Set modifications
      if(this.values)
        await this.player?.setModifications(this.values);
    },

    /**
     * Start player
     * @return {Promise<void>}
     */
    async start() {
      // check if code exists
      if(!this.code) return;

      // Create player
      this.player = new Preview(this.$refs.preview, 'player', 'public-u8ei1kfxohyoj6t19znv6h9h');

      // Set source
      this.player.onReady = async () => {
        await this.player.setSource(JSON.parse(this.code));
        await this.updateValues();
        //await player.setSource(this.code);
        //await this.player.setModifications(this.values || {});
        //await player.setTime(15);
        //await player.play();
      }
    }
  },

  computed: {
    previewStyles() {
      // Return styles
      return {
        width: this.width+"px",
        height: this.height+"px",
        overflow: 'hidden'
      }
    }
  }
}

</script>
