<template>
  <div class="template-settings row q-gutter-x-md"  v-if="template?.id && width && height">

    <div class="column">
      <div class="preview bg-grey-11">
        <media-preview :code="template.template_code" :width="width" :height="height" :values="template.values"/>
      </div>

      <q-btn flat class="bg-blue-1" icon="edit" label="Update template" @click="$emit('update-code')"/>
    </div>

    <div class="assets col">
      <div v-for="([name, val], k) of Object.entries(templateVars || [])" :key="k">
        <q-input v-model="mutate(template).prompts[name]" :label="`Prompt to generate ${name} string`" :placeholder="val.text" :hint="val.text ? `Prompt to generate: ${val.text}` : undefined"/>
      </div>
    </div>

  </div>
</template>

<script>

import MediaPreview from "@/components/MediaPreview.vue";
import {renderMixins} from "app-to-up-vue-renderer/src/renderer/components/renderMixins";

export default {
  name: "TemplateSettings",
  mixins: [renderMixins],
  components: {MediaPreview},
  emits: ['update-code'],
  data: () => ({
    width: 0,
    height: 0
  }),
  created() {
    this.$watch("template", () => {
      if(this.template) {
        this.mutate(this.template).prompts = this.template.prompts || {}
        this.updateSize();
      }
    }, {deep: true, immediate: true});
  },
  props: {
    template: {},
    staticWidth: {
      type: Number,
      default: 400
    }
  },

  computed: {

    /**
     * Get template vars
     * @return {{}}
     */
    templateVars() {

      // Texts to translate
      const textsToReplace = {};

      // Parse template source
      const templateSource = JSON.parse(this.template.template_code || "{}")

      // Go through all elements
      const getAllVars = (node) => {
        // Extract text assets from source
        for (const e of node?.elements || []) {
          if (e.dynamic) textsToReplace[e.name || e.id] = {
            text: e.text,
            type: e.type
          }
          getAllVars(e)
        }
      }

      // Process source
      getAllVars(templateSource);

      // Return texts
      return textsToReplace
    }
  },

  methods: {

    /**
     * Update size
     */
    updateSize() {

      // Get template params by type
      (this.parentDiagram.getStorage("constant").get("guides.mediaSizes") || []).map(el => {

        // Check type
        if(el.id === this.template?.subtype) {
          this.width = this.staticWidth;
          this.height = this.staticWidth / eval(el.ratio);
        }
      });
    }
  }
}

</script>

<style lang="scss">

.template-settings {
}

</style>
