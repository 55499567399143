
import {Projects} from './db/Projects.js';
import {Accounts} from './db/Accounts.js';
import {Users} from './db/Users.js';
import {AccountCampaigns} from './db/AccountCampaigns.js';
import {CampaignGroups} from './db/CampaignGroups.js';
import {GroupAssets} from './db/GroupAssets.js';
import {ProjectAssets} from './db/ProjectAssets.js';
import {PromoTemplates} from './db/PromoTemplates.js';
import {ProjectMedia} from './db/ProjectMedia.js';
import {ProjectSolutions} from './db/ProjectSolutions.js';
import {SolutionLandings} from './db/SolutionLandings.js';
import {MediaInstances} from './db/MediaInstances.js';
import {SolutionAds} from './db/SolutionAds.js';
import {ProjectFeatures} from './db/ProjectFeatures.js';

export const moduleConfig = {
  plugins: {},

  mixin: {
    async created() {
      
    },

    async beforeUnmount() {
      
    },

    methods: {

      // Init after a2u initialization
      async initModulesOnAppInit(a2u) {
        // Log the code version
        console.log(`Init modules on app init`, a2u?.getCodeVersion());

        
      },
    }
  },

  customDbModels: {
    'projects': Projects,
'accounts': Accounts,
'users': Users,
'account_campaigns': AccountCampaigns,
'campaign_groups': CampaignGroups,
'group_assets': GroupAssets,
'project_assets': ProjectAssets,
'promo_templates': PromoTemplates,
'project_media': ProjectMedia,
'project_solutions': ProjectSolutions,
'solution_landings': SolutionLandings,
'media_instances': MediaInstances,
'solution_ads': SolutionAds,
'project_features': ProjectFeatures
  },
}
